/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        $(document).foundation(); // Foundation JavaScript
      
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.accordion-item-title').click(function(){
          $(this).next('.accordion-item-content').slideToggle(500);
          $(this).toggleClass('current');
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// Select all dropdowns
var dropdowns = document.querySelectorAll('.dropdown');

Array.prototype.forEach.call(dropdowns, function(dropdown) {
  var button = dropdown.querySelector('.dropdown-button');
  var menu = dropdown.querySelector('.dropdown-menu');
  var items = menu.querySelectorAll('.dropdown-item');

  // Toggle the visibility of the dropdown menu
  button.addEventListener('click', function() {
    menu.classList.toggle('show');
  });

  // Handle item selection
  Array.prototype.forEach.call(items, function(item) {
    item.addEventListener('click', function(e) {
      var url = e.target.getAttribute('data-url');
      button.textContent = e.target.textContent;
      menu.classList.remove('show');
      if (url) {
        window.open(url, '_blank');
      }
    });
  });

  // Close dropdown if clicking outside
  document.addEventListener('click', function(e) {
    if (!dropdown.contains(e.target)) {
      menu.classList.remove('show');
    }
  });
});